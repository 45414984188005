import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { mixlActionTypes } from './redux/mixl/types';
import { playerActionTypes } from './redux/player/types';
import { rootReducer } from './redux/root-reducer';
import { businessMiddleware } from './redux/rootMiddleware';
import { isCapacitor, platforInfo } from './utils/platformUtils';

const middlewares = [thunk, businessMiddleware];
const persistConfig = {
  key: 'letsglo',
  storage,
  whitelist: ['web3NetworkData']
};

if (import.meta.env.MODE === 'development') {
  if (!isCapacitor || (isCapacitor && platforInfo.includes('desktop'))) {
    const logger = createLogger({
      predicate: (getState, action) => {
        switch (action.type) {
          case playerActionTypes.UPDATE_PLAYER_PROGRESS:
          case mixlActionTypes.SET_TIMER_VALUE:
          case mixlActionTypes.SET_BREAK_TIME:
          case mixlActionTypes.SET_SESSION_DATA:
          case mixlActionTypes.UPDATE_HEART_RATE:
          case mixlActionTypes.UPDATE_OXYGEN_LEVEL:
            return false;
          default:
            return true;
        }
      }
    });
    middlewares.push(logger);
  }
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);

if (import.meta.env.MODE === 'development') {
  window.appStore = store;
}
