import { ORMURL } from '../constants/Url';

const Request = () => {
  const { Prod, baseUrl } = ORMURL();

  const GetSecuredData = async (url: string, token: string) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      }
    });
    const data = await response.json();
    return data;
  };

  const PostSecuredData = async (url: string, payload: Record<string, unknown>, token: string) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const PostDataCustomUrl = async (customUrl: string, payload: Record<string, unknown>, token?: string) => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    const response = await fetch(`${customUrl}`, {
      method: 'POST',
      headers,
      body: payload ? JSON.stringify(payload) : {}
    });
    const data = await response.json();
    return data;
  };

  const PutDataCustomUrl = async (customUrl: string, payload: Record<string, unknown>, token?: string) => {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    const response = await fetch(`${customUrl}`, {
      method: 'PUT',
      headers,
      body: payload ? JSON.stringify(payload) : {}
    });
    const data = await response.json();
    return data;
  };

  const PostData = async (url: string, payload: Record<string, unknown>) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const PutData = async (url: string, payload: Record<string, unknown>, token?: string) => {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (token) {
      headers['Authorization'] = token;
    }
    const response = await fetch(`${Prod}${url}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };

  const DeleteSecuredData = async (url: string, token: string, payload?: Record<string, unknown>) => {
    const response = await fetch(`${Prod}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token
      },
      body: payload && JSON.stringify(payload)
    });

    const data = await response.json();
    if (response.status !== 200) {
      console.log(data);
      throw new Error(`Error: ${data.message}`);
    }
    return data;
  };

  const PostAiData = async (url: string, payload: Record<string, unknown>) => {
    const response = await fetch(`${baseUrl}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data;
  };


  return {
    PostData,
    PutData,
    GetSecuredData,
    PostSecuredData,
    PostDataCustomUrl,
    DeleteSecuredData,
    PutDataCustomUrl,
    PostAiData
  };
};

export default Request;
