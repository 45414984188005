import { IMixlSong } from 'mixl-audio';

import { IMusicContent } from '../redux/mixl/types';

export const getMixlSong = (payload: IMusicContent) => {
  console.log('getMixlSong: ', payload);
  if (payload.contentType === 'feed' && payload.content) {
    const binaural_music_url = payload.content?.music_url;
    const permalink_url = payload?.content?.music_url;
    let audio_url = permalink_url;
    if (binaural_music_url) {
      audio_url = binaural_music_url;
    }
    const artwork_url = payload.content.image_url ?? '';
    const result: IMixlSong = {
      id: payload.content.id,
      artwork_url: artwork_url,
      audio_url
    };
    return result;
  }

  return null;
};

export const prioritiesList = [
  {
    color: '#7A7A7A',
    id: '1',
    orderindex: '1',
    priority: 'low'
  },
  {
    color: '#FFF',
    id: '2',
    orderindex: '2',
    priority: 'normal'
  },
  {
    color: '#FE4D8A',
    id: '3',
    orderindex: '3',
    priority: 'high'
  },
  {
    color: '#B52758',
    id: '4',
    orderindex: '4',
    priority: 'urgent'
  }
];
