import Request from './Request';

const { PostAiData, _PostDataCustomUrl } = Request();

export const getAgentResponse = async (message, history, bot_action = '', isUserInSession) => {
  const data = await PostAiData(`mixl_agent/coach`, {
    message,
    history,
    bot_action,
    isUserInSession: Boolean(isUserInSession)
  });
  const response = data;
  return response;
};
