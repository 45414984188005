import FlowIcon from '../assets/icons/mixl/badges/flow.svg';
import GuruIcon from '../assets/icons/mixl/badges/guru.svg';
import StarterIcon from '../assets/icons/mixl/badges/starter.svg';
import SteadyIcon from '../assets/icons/mixl/badges/steady.svg';
import TeamIcon from '../assets/icons/mixl/badges/team.svg';

export function getUserName(name) {
  if (!name) {
    return '';
  }
  if (name.length > 18) {
    return `${name.substring(0, 18)}...`;
  }

  return name;
}

export const TEAM_BADGES = {
  starter: {
    icon: StarterIcon,
    name: 'starter'
  },
  steady: {
    icon: SteadyIcon,
    name: 'steady'
  },
  team: {
    icon: TeamIcon,
    name: 'team'
  },
  flow: {
    icon: FlowIcon,
    name: 'flow'
  },
  guru: {
    icon: GuruIcon,
    name: 'guru'
  }
};
