import { isCalendarViewOnly, isDesktopApp } from '../utils/platformUtils';

export const ALLOWED_DURATION_FOR_REVIEW = 0;

export function getContainerWidth() {
  if (window.innerWidth > 768) {
    return '414px';
  }

  if (isDesktopApp) {
    return '414px';
  }

  if (isCalendarViewOnly) {
    return `${Math.max(360, window.innerWidth)}px`;
  }

  return '100%';
}

const positiveColor = '#9747FF';
const negativeColor = '#FF004D';
export const getColorHex = (value, threshold = 100) => {
  if (!value) {
    return '#FFFFFF';
  }
  if (value / threshold >= 0.5) {
    return positiveColor;
  }
  return negativeColor;
};
