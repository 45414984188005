import { ISafeAreaInsets, SHOW_AGENT_VIEW, UPDATE_KEYBOARD_HEIGHT, UPDATE_SAFE_AREA } from './type';

export const updateSafeAreaInsets = (insets: ISafeAreaInsets) => {
  return {
    type: UPDATE_SAFE_AREA,
    insets
  };
};

export const updateKeyboardHeight = (height: number) => {
  return {
    type: UPDATE_KEYBOARD_HEIGHT,
    height
  };
};

export const updateShowAgentView = (isVisible: boolean) => {
  return {
    type: SHOW_AGENT_VIEW,
    isVisible
  };
};
