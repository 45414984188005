import gag from '../assets/icons/9gag.svg';
import amazon from '../assets/icons/amazon_icon.svg';
import discord from '../assets/icons/discord_icon.svg';
import facebook from '../assets/icons/facebook-icon.svg';
import instagram from '../assets/icons/instagram_icon.svg';
import blackLink from '../assets/icons/link-black-icon.svg';
import linkedin from '../assets/icons/linkedin_icon.svg';
import medium from '../assets/icons/medium.svg';
import netflix from '../assets/icons/netflix.svg';
import pinterest from '../assets/icons/pinterest_icon.svg';
import prime from '../assets/icons/prime_icon.svg';
import reddit from '../assets/icons/reddit_icon.svg';
import snapchat from '../assets/icons/snapshat_icon.svg';
import telegram from '../assets/icons/telegram.svg';
import threads from '../assets/icons/threads.svg';
import tiktok from '../assets/icons/tiktok_icon.svg';
import tumblr from '../assets/icons/tumblr_icon.svg';
import twitch from '../assets/icons/twitch_icon.svg';
import twitter from '../assets/icons/twitter_icon.svg';
import wechat from '../assets/icons/wechat.svg';
import youtube from '../assets/icons/youtube_icon.svg';
import { firebaseAuth, getUserToken } from '../components/firebase/firebase';
import { getEmailDomain } from '../utils/miscUtils';
import Request from './Request';

const { PostSecuredData, PostAiData } = Request();

export const updateClickupTaskName = async (taskId, taskName) => {
  if (!taskName) {
    return;
  }
  const token = await getUserToken();
  const { data } = await PostSecuredData(
    'mixer/update_clickup_task',
    {
      taskId,
      taskData: { name: taskName }
    },
    token
  );
  return data;
};

export const updateClickupTaskDetails = async (taskId, description) => {
  if (!description) {
    return;
  }
  const token = await getUserToken();
  const { data } = await PostSecuredData(
    'mixer/update_clickup_task',
    {
      taskId,
      taskData: { description }
    },
    token
  );
  return data;
};

export const updateClickupTaskProgress = async (taskId, statusText) => {
  if (!statusText) {
    return;
  }
  let status = statusText;
  if (statusText === 'done') {
    status = 'closed';
  }
  const token = await getUserToken();
  const { data } = await PostSecuredData(
    'mixer/update_clickup_task',
    {
      taskId,
      taskData: { status }
    },
    token
  );
  return data;
};
export const updateClickupTaskPriority = async (taskId, priorityType) => {
  let priority = 0;
  switch (priorityType) {
    case 'urgent':
      priority = 1;
      break;
    case 'high':
      priority = 2;
      break;
    case 'normal':
      priority = 3;
      break;
    default:
      priority = 4;
  }
  const token = await getUserToken();
  const { data } = await PostSecuredData(
    'mixer/update_clickup_task',
    {
      taskId,
      taskData: { priority }
    },
    token
  );
  return data;
};

export const getWebsiteIcon = (websiteName: string) => {
  let siteIcon = blackLink;
  if (websiteName.toLowerCase()?.includes('facebook')) {
    siteIcon = facebook;
  } else if (websiteName.toLowerCase()?.includes('snapchat')) {
    siteIcon = snapchat;
  } else if (websiteName.toLowerCase()?.includes('instagram')) {
    siteIcon = instagram;
  } else if (websiteName.toLowerCase()?.includes('youtube')) {
    siteIcon = youtube;
  } else if (websiteName.toLowerCase()?.includes('tiktok')) {
    siteIcon = tiktok;
  } else if (websiteName.toLowerCase()?.includes('twitter') || websiteName.toLowerCase() === 'x.com') {
    siteIcon = twitter;
  } else if (websiteName.toLowerCase()?.includes('linkedin')) {
    siteIcon = linkedin;
  } else if (websiteName.toLowerCase()?.includes('pinterest')) {
    siteIcon = pinterest;
  } else if (websiteName.toLowerCase()?.includes('tumblr')) {
    siteIcon = tumblr;
  } else if (websiteName.toLowerCase()?.includes('reddit')) {
    siteIcon = reddit;
  } else if (websiteName.toLowerCase()?.includes('primevideo')) {
    siteIcon = prime;
  } else if (websiteName.toLowerCase()?.includes('netflix')) {
    siteIcon = netflix;
  } else if (websiteName.toLowerCase()?.includes('twitch')) {
    siteIcon = twitch;
  } else if (websiteName.toLowerCase()?.includes('discord')) {
    siteIcon = discord;
  } else if (websiteName.toLowerCase()?.includes('amazon')) {
    siteIcon = amazon;
  } else if (websiteName.toLowerCase()?.includes('wechat')) {
    siteIcon = wechat;
  } else if (websiteName.toLowerCase()?.includes('9gag')) {
    siteIcon = gag;
  } else if (websiteName.toLowerCase()?.includes('telegram')) {
    siteIcon = telegram;
  } else if (websiteName.toLowerCase()?.includes('medium')) {
    siteIcon = medium;
  } else if (websiteName.toLowerCase()?.includes('threads')) {
    siteIcon = threads;
  }

  return siteIcon;
};

export const getDescription = async ({ taskName }) => {
  const token = await getUserToken();
  const userEmail = firebaseAuth.currentUser?.email;
  let payload = {
    task: taskName
  };
  if (userEmail) {
    payload = {
      ...payload,
      domain: getEmailDomain(userEmail)
    };
  }
  const data = await PostSecuredData(`mixer/get_task_details`, payload, token);
  console.log('getTaskDetails', data);
  const description = data?.data || '';
  return { description, type: 'description' };
};

export const getTaskPriority = async ({ taskName }) => {
  const token = await getUserToken();
  const data = await PostSecuredData(`mixer/get_task_priority`, { taskName }, token);
  const priority = data?.priority || 'high';
  return { priority, type: 'priority' };
};

export const getTaskCategory = async ({ taskName }) => {
  const token = await getUserToken();
  const data = await PostSecuredData(`mixer/get_task_catagory`, { taskName }, token);
  const tagName = data?.tagName;
  return { tagName, type: 'tagName' };
};

export const getBlockingSites = async (blockTimeGoal) => {
  const token = await getUserToken();
  const payload = {
    task: blockTimeGoal
  };
  const result = await PostSecuredData(`mixer/recommend_distractions`, payload, token);
  let data = result?.data || [];
  console.log('blocked sites', data);
  if (data && data.length) {
    data = getSitesWithIcon(data);
  } else {
    data = [];
    return data;
  }
  return { sites: data, type: 'blockedSites' };
};

function getSitesWithIcon(data: { Website?: string; Tag?: 'Allow' | 'Block' }) {
  const result = data.map((site) => {
    if (site?.Website?.toLowerCase()?.includes('facebook')) {
      site.icon = facebook;
      site.category = 'social';
    } else if (site?.Website?.toLowerCase()?.includes('snapchat')) {
      site.icon = snapchat;
      site.category = 'social';
    } else if (site?.Website?.toLowerCase()?.includes('instagram')) {
      site.icon = instagram;
      site.category = 'social';
    } else if (site?.Website?.toLowerCase()?.includes('youtube')) {
      site.icon = youtube;
      site.category = 'videos';
    } else if (site?.Website?.toLowerCase()?.includes('tiktok')) {
      site.category = 'social';
      site.icon = tiktok;
    } else if (site?.Website?.toLowerCase()?.includes('twitter') || site?.Website?.toLowerCase() === 'x.com') {
      site.category = 'social';
      site.icon = twitter;
    } else if (site?.Website?.toLowerCase()?.includes('linkedin')) {
      site.category = 'social';
      site.icon = linkedin;
    } else if (site?.Website?.toLowerCase()?.includes('pinterest')) {
      site.category = 'social';
      site.icon = pinterest;
    } else if (site?.Website?.toLowerCase()?.includes('tumblr')) {
      site.category = 'social';
      site.icon = tumblr;
    } else if (site?.Website?.toLowerCase()?.includes('reddit')) {
      site.category = 'social';
      site.icon = reddit;
    } else if (site?.Website?.toLowerCase()?.includes('primevideo')) {
      site.category = 'videos';
      site.icon = prime;
    } else if (site?.Website?.toLowerCase()?.includes('netflix')) {
      site.category = 'videos';
      site.icon = netflix;
    } else if (site?.Website?.toLowerCase()?.includes('twitch')) {
      site.category = 'sports';
      site.icon = twitch;
    } else if (site?.Website?.toLowerCase()?.includes('discord')) {
      site.category = 'social';
      site.icon = discord;
    } else if (site?.Website?.toLowerCase()?.includes('amazon')) {
      site.category = 'shopping';
      site.icon = amazon;
    } else if (site?.Website?.toLowerCase()?.includes('wechat')) {
      site.category = 'social';
      site.icon = wechat;
    } else if (site?.Website?.toLowerCase()?.includes('9gag')) {
      site.category = 'social';
      site.icon = gag;
    } else if (site?.Website?.toLowerCase()?.includes('telegram')) {
      site.category = 'social';
      site.icon = telegram;
    } else if (site?.Website?.toLowerCase()?.includes('medium')) {
      site.category = 'news';
      site.icon = medium;
    } else if (site?.Website?.toLowerCase()?.includes('threads')) {
      site.category = 'social';
      site.icon = threads;
    } else {
      site.icon = blackLink;
    }
    site.Tag = 'Block';
    return site;
  });
  return result;
}

// Function to get a random color
function _getRandomColor(colors) {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

export const getTaskFocusScore = async ({ taskName }) => {
  const data = await PostAiData(`mixl_agent/focus_score`, { task: taskName });
  const goalScore = data?.response;
  return { goalScore, type: 'goalScore' };
};
export const getSiteRelevance = async ({ goal, site_url, site_title }) => {
  const data = await PostAiData(`mixl_agent/site_relevance`, { goal, site_url, site_title });
  const siteRelevance = Math.round(data?.response * 2.5);
  return { siteRelevance, type: 'siteRelevance' };
};

export const getQueryFocusScore = async (goal, query) => {
  const data = await PostAiData(`mixl_agent/query_relevance`, { goal, query });
  const score = data?.response;
  return { score, type: 'queryScore' };
};

export const getBiometricsData = async (access_token) => {
  const token = await getUserToken();
  const isSandbox = import.meta.env.DEV ? true : false;
  const payload = { access_token, isSandbox };
  const heartData = await PostSecuredData('community/biometrics', payload, token);
  // console.log('heartData: ', heartData);
  return heartData;
};
