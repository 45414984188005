import { ISafeAreaInsets, IStyleData, SHOW_AGENT_VIEW, UPDATE_KEYBOARD_HEIGHT, UPDATE_SAFE_AREA } from './type';

const defaultAreaInset: ISafeAreaInsets = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const StyleState = {
  safeAreaInsets: defaultAreaInset,
  keyboardHeight: 0,
  showAgentView: true
};

export const StyleReducer = (state = StyleState, action): IStyleData => {
  switch (action.type) {
    case SHOW_AGENT_VIEW: {
      return { ...state, showAgentView: action.isVisible };
    }
    case UPDATE_KEYBOARD_HEIGHT: {
      return { ...state, keyboardHeight: action.height };
    }
    case UPDATE_SAFE_AREA: {
      return {
        ...state,
        safeAreaInsets: action.insets
      };
    }
    default:
      return state;
  }
};
