import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { firebaseAuth, firebaseDB } from '../components/firebase/firebase';
import { convertDateToToday, isTaskToday } from '../utils/dateUtils';
import { setEventType } from '../utils/flowCoachUtils';
import { IMixlEvent, IMixlTask } from '../utils/Types';

export const isWorkEvent = (eventName) => {
  if (eventName === 'Flow' || eventName === 'Quick Todos' || eventName === 'Learning') {
    return true;
  }
  return false;
};

export const isWorkTypeEvent = (eventType) => {
  if (eventType === 'work' || eventType === 'todo' || eventType === 'learning' || eventType === 'goal') {
    return true;
  }
  return false;
};

export const BUSY_EVENTS = [
  {
    name: 'Daily Goal',
    color: '#FF1D7F',
    tag: 'Flow',
    taskType: 'work'
  },
  {
    name: 'Quick Task',
    color: '#FA1DFF',
    tag: 'Quick Task',
    taskType: 'todo'
  },
  {
    name: 'Learning',
    color: '#3AAECA',
    tag: 'Learning',
    taskType: 'learning'
  },
  {
    name: 'Break',
    color: '#00C57E',
    tag: 'Break',
    taskType: 'break'
  },
  {
    name: 'Exercise',
    color: '#FC6949',
    tag: 'Exercise',
    taskType: 'exercise'
  },
  {
    name: 'Meeting',
    color: '#7F96FF',
    tag: 'Meeting',
    taskType: 'meeting'
  }
];

export const getBusyEventColor = (eventName: string) => {
  const eventType = BUSY_EVENTS.find((item) => item.name === eventName);
  if (eventType) {
    return eventType.color;
  }

  return '#00C57E';
};

export const generateRandomId = () => Math.floor(Math.random() * 1000000);

export const convertMixlEvent = (item: IMixlEvent, taskData?: IMixlTask) => {
  const result = { ...item } as IMixlEvent;
  if (!result?.type) {
    result.type = setEventType(taskData);
  }
  result.start = new Date(item.startDateString);
  result.end = new Date(item.endDateString);
  result.task = { ...taskData };
  result.taskPriority = taskData?.priority?.priority ?? item?.taskPriority ?? 'normal';
  result.taskStatus = taskData?.status?.status ?? item?.taskStatus ?? 'open';

  return result;
};

export const getMixlEventsWithTaskData = async (eventList: IMixlEvent[], taskList: IMixlTask[]) => {
  if (!eventList.length) {
    return [];
  }
  const result = await Promise.all(
    eventList.map((item) => {
      const eventId = item.id;
      const taskData = taskList.find((task) => task.id === eventId);
      if (!taskData) {
        if (!item?.type && item.title === 'Break') {
          console.log('getMixlEventsWithTaskData: not work type ');
          item.start = new Date(item.startDateString);
          item.end = new Date(item.endDateString);
          return item;
        }
        deleteDaySchedule(item);
        return null;
      }
      const updateStartDate = convertDateToToday(item.startDateString);
      if (updateStartDate) {
        item.startDateString = updateStartDate;
      }
      const updateEndDate = convertDateToToday(item.endDateString);
      if (updateEndDate) {
        item.endDateString = updateEndDate;
      }
      const mixlEventData = convertMixlEvent(item, taskData);
      return mixlEventData;
    })
  ).then((objectList) => objectList.filter((obj) => obj !== null));

  return result;
};

export const getUnscheduledTaskList = (tasklist: IMixlTask[], eventList: IMixlEvent[]) => {
  const scheduledTasks = [];
  const unScheduledTasks = [];
  for (const taskData of tasklist) {
    const hasEvent = eventList.find((item) => item.id === taskData.id);
    if (hasEvent) {
      scheduledTasks.push({
        ...taskData,
        startTimestamp: hasEvent.start?.getTime(),
        endTimestamp: hasEvent.end?.getTime()
      });
    } else {
      unScheduledTasks.push({ ...taskData });
    }
  }
  return { unScheduledTasks, scheduledTasks };
};

export const fetchMixlTaskList = async () => {
  const uid = firebaseAuth.currentUser?.uid;
  const colRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');
  const mixlTasks = await getDocs(colRef).then(async (snapshot) => {
    console.log('mixlTasks ', snapshot.size);
    if (!snapshot.size) {
      return [];
    }
    const list = await Promise.all(
      snapshot.docs.map((item) => {
        const data = item.data();
        const dateCreated = data?.createdAt ?? Date.now();
        const startTimestamp = data?.startTimestamp ?? dateCreated;
        const isTodayTask = isTaskToday(startTimestamp);
        const mixlTask: IMixlTask = {
          ...item.data(),
          id: item.id,
          isTodayTask,
          startTimestamp
        };
        return mixlTask;
      })
    );
    return list;
  });
  return mixlTasks;
};
export const fetchScheduleToday = async (userId) => {
  const today = DateTime.now().toFormat('yyyy-LLLL-dd');
  const eventList = await fetchDaySchedule(userId, today);

  return eventList;
};

export const fetchDaySchedule = async (userId) => {
  const collectionRef = collection(firebaseDB, 'glo-users', userId, 'mixl_events');

  const querySnapshot = await getDocs(collectionRef);

  if (querySnapshot.empty) {
    return [];
  }
  const eventList = await Promise.all(
    querySnapshot.docs.map((doc) => {
      const eventData = doc.data() as IMixlEvent;
      return eventData;
    })
  );
  return eventList;
};

export const deleteDailyDocuments = async () => {
  try {
    const uid = firebaseAuth.currentUser?.uid;
    const collectionRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');
    // Fetch all documents from the collection
    const querySnapshot = await getDocs(collectionRef);

    // Check if the collection exists (i.e., if there are any documents)
    if (!querySnapshot.empty) {
      const deletePromises = [];

      // Iterate through each document and delete it
      querySnapshot.forEach((document) => {
        const docRef = doc(firebaseDB, 'glo-users', uid, 'mixl_events', document.id);
        deletePromises.push(deleteDoc(docRef));
      });

      // Wait for all delete operations to complete
      await Promise.all(deletePromises);
      console.log('All documents deleted successfully.');
    } else {
      console.log('No documents found in the collection.');
    }
  } catch (error) {
    console.error('Error deleting documents: ', error);
  }
};

export const deleteDaySchedule = async (mixlEvent: IMixlEvent) => {
  try {
    const uid = firebaseAuth.currentUser?.uid;
    const collectionRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');
    const queryCal = query(collectionRef, where('id', '==', mixlEvent.id));
    const querySnapshot = await getDocs(queryCal);
    console.log('deleteDaySchedule: ', querySnapshot.size);
    if (querySnapshot.size) {
      querySnapshot.docs.forEach((docRef) => {
        const docId = docRef.id;
        deleteDoc(doc(collectionRef, docId));
      });
    }
  } catch (error) {
    console.log('err: deleteDaySchedule ', error);
  }
};

export const editDaySchedule = async (mixlEvent: IMixlEvent) => {
  const uid = firebaseAuth.currentUser?.uid;
  const collectionRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');
  const id = mixlEvent?.id;
  if (!id) return;

  // Query Firestore to check if the event with this id exists
  const queryCal = query(collectionRef, where('id', '==', id));
  const querySnapshot = await getDocs(queryCal);
  if (!querySnapshot.empty) {
    // Get the document reference for the existing event
    const docRef = querySnapshot.docs[0].ref;

    // Update the document
    await updateDoc(docRef, {...mixlEvent});
    console.log('Event updated:', id);
  } else {
    console.log('Event not found:', id);
  }
};

export const saveDaySchedule = async (eventList: Array<IMixlEvent>) => {
  const uid = firebaseAuth.currentUser?.uid;
  const _eventDate = DateTime.now().toFormat('yyyy-LLLL-dd');
  const collectionRef = collection(firebaseDB, 'glo-users', uid, 'mixl_events');
  for await (const mixlEvent of eventList) {
    const id = mixlEvent.id;
    if (!id) return;
    const queryCal = query(collectionRef, where('id', '==', id));
    const querySnapshot = await getDocs(queryCal);
    if (querySnapshot.size > 0) {
      // console.log('saveDaySchedule: editing');
      await editDaySchedule(mixlEvent);
    } else {
      await addDoc(collectionRef, mixlEvent);
    }
  }
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d.getTime());
}
