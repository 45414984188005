export const EXTERNAL_BASE_SERVICE_URL = 'dev-app.letsglo.com';
export const EXTERNAL_SERVICE_URL = `https://${EXTERNAL_BASE_SERVICE_URL}`;

export const ORMURL = () => {
  // fetch dashboard url and return each respective orm url
  const hostname = window.location.hostname;
  const isLocalhost = hostname.startsWith('localhost');
  const isDev = import.meta.env.DEV;
  let baseUrl = `https://${hostname}`;
  if (isDev || isLocalhost) {
    baseUrl = EXTERNAL_SERVICE_URL;
    // !NOTE: uncomment to use local orm.
    // return { Prod: 'http://localhost:8080/', baseUrl: 'http://localhost:8080' };
  }
  return { Prod: `${baseUrl}/orm/`, baseUrl };
};
