import { MixlAudioPlayer } from 'mixl-audio';

import { sleep } from '../../utils/miscUtils';
import { getMixlSong } from '../../utils/musicFeedUtils';
import { isCapacitor, isExtenstionSidebar, postIframeMessage } from '../../utils/platformUtils';
import { IMusicContent } from '../mixl/types';
import { RootState } from '../root-reducer';
import { playerActionTypes } from './types';

export const QUIZE_TYPE = 'quiz';

export const updateSongDuration = (payload) => (dispatch) => {
  dispatch({
    type: playerActionTypes.UPDATE_MUSIC_DURATION,
    payload
  });
};

export const pauseMusicTrack =
  (notifyNative = true) =>
  async (dispatch) => {
    console.log('pauseMusicTrack');
    if (isCapacitor && notifyNative) {
      await MixlAudioPlayer.pauseAudio();
    }
    if (isExtenstionSidebar()) {
      postIframeMessage({ type: 'pause_music' });
    }
    dispatch({
      type: playerActionTypes.PAUSE_MUSIC_TRACK
    });
  };

export const resumeMusicTrack =
  (notifyNative = true) =>
  async (dispatch) => {
    console.log('resumeMusicTrack');
    if (isCapacitor && notifyNative) {
      await MixlAudioPlayer.resumeAudio();
    }
    if (isExtenstionSidebar()) {
      postIframeMessage({ type: 'resume_music' });
    }
    dispatch({
      type: playerActionTypes.RESUME_MUSIC_TRACK
    });
    console.log('resume_music');
  };

/**
 * Play music with music player.
 * @param {IMusicContent} payload - music payload
 * @returns
 */
export const playMusicTrack = (payload: IMusicContent) => async (dispatch, getState) => {
  console.log('playMusicTrack fadein');
  const store: RootState = getState();
  const mixlSong = getMixlSong(payload);
  console.log('mixlSong', mixlSong);
  const { playerVolume, mainVolume } = store.mixlData;
  console.log('[pause] audio', mainVolume);
  dispatch({
    type: playerActionTypes.PAUSE_MUSIC_TRACK
  });
  if (isCapacitor) {
    MixlAudioPlayer.playAudio({ mixlSong, volume: playerVolume });
  }
  sleep(250).then(() => {
    dispatch({
      type: playerActionTypes.PLAY_MUSIC_TRACK,
      musicUrl: mixlSong?.audio_url
    });
  });
};

export const setNotificationData = (data) => (dispatch) => {
  dispatch({
    type: playerActionTypes.SET_NOTIFICATION_DATA,
    payload: data
  });
};

export const pauseMusicTrackAction =
  (notifyNative = true) =>
  async (dispatch) => {
    if (isCapacitor && notifyNative) {
      await MixlAudioPlayer.pauseAudio();
    }
    if (isExtenstionSidebar()) {
      postIframeMessage({ type: 'pause_music' });
    }
    dispatch({
      type: playerActionTypes.PAUSE_MUSIC_TRACK
    });
  };

export const resumeMusicTrackAction =
  (notifyNative = true) =>
  async (dispatch) => {
    if (isCapacitor && notifyNative) {
      await MixlAudioPlayer.resumeAudio();
    }
    if (isExtenstionSidebar()) {
      postIframeMessage({ type: 'resume_music' });
    }
    dispatch({
      type: playerActionTypes.RESUME_MUSIC_TRACK
    });
  };

/**
 * Play music with music player.
 * @param {IMusicContent} payload - music payload
 * @returns
 */
export const playMusicTrackAction = (payload: IMusicContent) => async (dispatch, getState) => {
  console.log('playMusicTrack');
  const store: RootState = getState();
  const mixlSong = getMixlSong(payload);
  const { playerVolume } = store.mixlData;
  dispatch({
    type: playerActionTypes.PAUSE_MUSIC_TRACK
  });
  if (isCapacitor) {
    MixlAudioPlayer.playAudio({ mixlSong, volume: playerVolume });
  }
  sleep(250).then(() => {
    dispatch({
      type: playerActionTypes.PLAY_MUSIC_TRACK,
      musicUrl: mixlSong?.audio_url
    });
  });
};
